import * as Common from 'components/_common/Common'
import {
  customProps,
  ICustomStyledProps,
} from 'components/_common/commonStyles'
import { commonTypographyStyles } from 'components/_common/Typography'
import styled, { css } from 'styled-components'

export const transparentCss = css`
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
`

export const Div = styled.div<ICustomStyledProps>(
  () => css`
    display: block;
    ${customProps};
  `
)

export const Input = styled.input<ICustomStyledProps>(
  () => css`
    ${customProps};
  `
)

export const Label = styled.label(
  ({ theme: { spacing } }) => css`
    ${commonTypographyStyles};
    margin-bottom: ${spacing(1)};
    font-size: 16px;
    text-align: left;
  `
)

export const Card = styled(Div)(
  ({ theme: { colors } }) => css`
    ${customProps};
    font-size: 32px;
    background: ${colors.background};
    -webkit-box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  `
)

export const DropdownWithIcon = styled(Common.Div)(
  ({ theme: { colors, spacing, isKiosk }, isMobile }) => css`
    ${customProps};
    border: 1px solid ${colors.border};
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 50px;
    width: ${isKiosk ? '324px' : 'min(366px, 100%)'};
    padding: 0 ${spacing(2)};
    justify-content: space-between;
    min-width: ${isMobile ? '200px' : '332px'};

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  `
)

export const DropdownModal = styled(Common.Div)(
  ({ theme: { spacing, isKiosk } }) => css`
    position: absolute;
    padding: ${spacing(3)};
    border: none;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    z-index: 99;
    ${transparentCss};

    ${isKiosk &&
    css`
      top: 154px;
    `}
  `
)

export const SvgIcon = styled.img<ICustomStyledProps>(
  ({ onClick }) => css`
    ${customProps};
    ${onClick && 'cursor: pointer'};
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  `
)

export const BarBorder = styled.div<ICustomStyledProps>(
  ({ theme: { colors } }) => css`
    ${customProps};
    border: 0.5px solid ${colors.border};
  `
)

export const KioskViewContainer = styled(Common.Div)<
  ICustomStyledProps & { bgUrl?: string }
>(
  ({ bgUrl, theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    ${bgUrl
      ? css`
          background: url(${bgUrl}) no-repeat;
          background-size: cover;
          background-attachment: fixed;
        `
      : `background-color: ${colors.background}`};

    align-items: center;
    height: calc(100vh - 80px - 44px);
    width: 100%;
  `
)
